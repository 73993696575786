$font-family: 'Manrope', sans-serif;
$font-size: 16px;
$font-regular: 400;
$font-bold: 700;

$white: #ffffff;
$black: #1B1B1F;

$very-dark-gray: #404147;
$dark-gray: #696A76;
$gray: #84858A;
$light-gray: #D3D5DB;
$very-light-gray: #EAEBEE;
$ultra-light-gray: #F9F9F9;

$dark-red: #C61C1C;
$red: #FF2F2F;
$light-red: #FF5959;
$very-light-red: #FFF3F3;

$green: #1DC25F;
$very-light-green: #E5FFEF;

$orange: #FF6E1D;

$very-light-yellow: #FFF8E1;

$primary: #7F47C5;
$primary-light: #996CD1;
$primary-dark: #66399E;
$primary-very-light: #F3FBFF;
$primary-border: #AFE2FF;
$primary-product: #0065C1;

$link: #2F44FF;
$link-hover: #247CFF;

$logout-gray: #bdbdbd;
$brand-bg: #F2F2F2;
$footer-bg: #333333;
$footer-checkout-bg: #303030;
$catalog-subcategory-count: #4F4F4F;
$label-hit: #B213B6;
$label-credit: #FF2F2F;
$label-credit-bg: #FFF3F3;
$label-sale: #5113B6;
$label-sale-bg: #E9E1FF;
$offcanvas-category: #FAF9F9;

$subfooter-bg: #1B1B1F;
$footer-link: #ffffff;
$footer-text: #ffffff;
